<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    SOIL NUTRISENSE
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <p class="mt-10">
                        In soil-grown greenhouse crops, part of the nutrients required by the plants is applied as a base-dressing. This is particularly the case with P (Sonneveld, 1995), which is rather immobile in the soil. In contrast, nitrogen, which is highly soluble in water as nitrate and ammonium salts, is supplied to the crop after planting through the irrigation system. This operation, which is widely known as fertigation, saves time, labour, and resources while maintaining or even improving crop yields. As a result, the water use efficiency increases considerably, provided that the dosages of both water and nutrients are properly assigned.  The use of suitable dosing pumps or injectors enabling the maintenance of optimal nutrient concentrations in the irrigation water supplied to the crop is a prerequisite for balanced plant nutrition. Nevertheless, the “optimal nutrient concentrations” need to be adjusted and specialized for each particular greenhouse crop species.
                    </p>

                    <v-layout justify-center align-center>
                        <v-flex shrink>
                            <div><v-img class="mt-3" :src="require('@/assets/about_c/figure_1.png')" /></div>
                        </v-flex>
                    </v-layout>
                    <v-layout justify-center align-center>
                        <v-flex shrink>
                            <div><small style="display:inline-block; width: 40vw;margin-left: 30px;"><strong>Figure 1.</strong> Schematic outline of the calculation of the proper fertigation for a soil-grown crop via DSS NUTRISENSE. The input data to DSS are the soil analysis, the plant developmental stage, the mineral composition of the irrigation water, the plant nutrient status, and the climatic conditions. The output of DSS is the fertigation formula which is applied to the crop via the fertigation system. </small></div>
                        </v-flex>
                    </v-layout>

                    <p class="mt-3">
                        In many parts of the word, including most Mediterranean countries, many greenhouse growers still determine fertilizer application rates by a “rule of thumb”. In most cases, this practice results in excessive application rates of nitrogen, phosphorus and, less frequently, potassium. In some cases, excessive application of one or more nutrients is accompanied by an inadequate supply of other nutrients, thereby exacerbating the incidence of single nutrient toxicities or deficiencies, or even resulting in multi-nutritional disorders. To prevent such problems, balanced fertilization schemes based on knowledge of plant nutrient requirements and soil nutrient reserves, as determined by chemical soil analysis, are needed. Hence, optimal fertilizer application rates for each nutrient can be estimated by deducting the soil reserves from the total plant requirements. However, the practical application of this concept by growers is not a simple issue. A major difficulty arises from the many methods and concepts used to estimate the levels of available plant nutrients in the soil. Another problem is that even the most credible and accurate soil analysis data need interpretation and conversion into quantitative recommendations in the form of target nutrient concentrations in the fertigation solution.
                    </p>
                    <p>
                        The classical approach for estimating plant nutrient availability in the soil is the determination of exchangeable nutrient cations, which is based on the use of acetic acid and DTPA solutions for the extraction of plant available macro- and micro-cations, respectively (<strong>Gianquinto et al., 2013</strong>). Critical levels of exchangeable cations in greenhouse soils are given in Table 1 based on experience and standard laboratory practices. However, the determination of exchangeable cations is laborious and time consuming, since it requires the drying of soil samples. Furthermore, this method is restricted only to cations, while the determination of essential nutrients occurring as anions (P, N) or uncharged compounds (B) requires other procedures, for example, the Olsen method which is routinely used to estimate the plant available P in Mediterranean soils (<strong>Olsen and Sommers, 1982</strong>). Another approach that has been standardized in the Netherlands over the last decades is the determination of water-soluble nutrients in water extracts (<strong>de Bos et al., 1999</strong>). According to this method, the water-soluble nutrients contained in the soil are determined in 1:2 (soil to water in v/v) extracts (<strong>Sonneveld and Voogt, 2009</strong>). This method enables rapid estimation of the soil nutrient status because the samples do not require drying before the extraction process. However, a drawback of this method is that the measured concentrations do not reflect the actual nutrient levels in the soil solution due to variations in soil texture. Therefore, an accurate interpretation of results obtained for a specific soil type with this method is possible only after testing and adaptation to the current soil type.
                    </p>
                    <p>
                        The use of a DSS after a soil analysis to precisely formulate NS for soil-grown crops similar to those applied in soilless culture will considerably restrict overfertilization and thus soil and water pollution with N and P.  Moreover, optimizing the nutrient management of soil-grown crops helps the economic sustainability of the growers due to higher yield production and reduced fertiliser consumption. A lower synthetic fertiliser input will also lead to energy saving from the fertiliser industry, thereby reducing CO2 emissions.
                    </p>
                    <p>
                        DSS NUTRISENSE, which currently supports soilless growers, will be further developed as DSS NUTRISENSE SOIL for irrigated soil-grown horticultural crops. The DSS NUTRISENSE SOIL will precisely calculate fertiliser requirements for basal dressing and fertigation of diverse crop species, mostly open-field and greenhouse vegetables. The further development of NUTRISENSE, will match the special needs of soil-grown crops and optimize fertilizer application rates. This DSS will be used to precisely calculate the amounts of fertilisers needed to prepare an NS using a wide arrow of water-soluble fertilizers, for the fertigation of a particular crop species. Apart from the crop species, the calculations will take into consideration a soil analysis, the mineral composition of the irrigation water, the season of the year, and the plant developmental stage. Furthermore, SOIL NUTRISENSE would be able to adjust the fertigation via irrigation using data from a soil analysis, and plant nutrient status during the cultivation period. The SOIL NUTRISENSE DSS will be developed, tested, and experimentally validated in greenhouse facilities of stakeholders in a Mediterranean environment, who will receive full access and support within the framework of ECONUTRI to apply it at a commercial scale.
                    </p>
                    <h2>Literature cited</h2>
                    <p class="small-text">
                        de Bos et al., 1999
                        <br>
                        Gianquinto, G.P., Muñoz, P., Pardossi, A., Ramazzotti, S., Savvas, D., 2013. Chapter 10: Soil fertility and plant nutrition. In: Good Agricultural Practices for Greenhouse Vegetable Crops. Principles for Mediterranean Climate Areas. Food and Agriculture Organization of the United Nations, Plant Production and Protection Paper 217, Rome, pp. 205-269. (<a href="http://www.fao.org/3/a-i3284e.pdf" target="_blank">http://www.fao.org/3/a-i3284e.pdf</a>).
                        <br>
                        Olsen and Sommers, 1982
                        <br>
                        Sonneveld, C., 1995. Fertigation in the greenhouse industry. In: Proceedings of the Dahlia Greidinger International Symposium on Fertigation. Technion - Israel Institute of Technology, Haifa, Israel, pp. 121-140.
                        <br>
                        Sonneveld, C., Voogt, W., 2009. Plant Nutrition of Greenhouse Crops. Springer, Dordrecht Heidelberg, London, New York. 431 p.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
ol {
    color: #383838;
    font-weight: bold;
}
.spacer {
    display: inline-block;
    width: 40px;
}
strong {
    color: #383838;
}
</style>
